

.MenuItem a{
    list-style-type: none;
    width: 100px;
    justify-content: center;
    display: inline-flex;
    float: right;
    margin : 0px 0px;
    padding: 9px;
    font-weight: 900;    
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    color: white;
}

.MenuItem a:hover,
.MenuItem a:active
{
    background-color: white;
    color: black;
    font-weight: 900;  
    
}




