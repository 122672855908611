.MainAreaold{
    width: 67%;
    height: 645px;
    display: inline-flex;
    flex-wrap: wrap;
    overflow: scroll;
    background-color:#F8F8FF;
    margin-left: 5px;
    font-family: inherit;
}

.MainArea{
    width: 100%;
    display: flex;
    height: 640px;
    box-sizing: border-box;
    
}