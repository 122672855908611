.Logo{
    height: 100px;;
    width: 100px;
    

}

.Logo img{
    height: 80%;
    width: 80%;
    border-radius: 4px 4px;
    margin:4px;
    
}

.Upaya{
    width: 90%;
    font-weight: 900;
    text-align: center;
    background-color: #FEEEA2;
    border-radius: 4px 4px 0px 0px;
    color: black
}

.GovSchemes{
    color: black;
    width: 90%;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background-color: #FEEEA2;
    border-radius: 0px 0px 4px 4px;
}