.Toolbar{
    width: 100%;
    height: 5%;
    background-color: #8fbee7;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    position:sticky;
    font-family: inherit;
    color: white;
    font-weight: 800;
    
}

.Search{
    margin: 0px 10px;
    width: 17%;
    height: 20px;
    padding: 2px;
    font-size: 17px;
    margin-left:auto;
    border-radius: 6px;
    font-family: inherit;
    border: 2px solid #FEEEA2;
    
}

.Toolbar select{
    margin: 0px 10px;
    border-radius: 6px;
    width: 200px;
    height: 29px;
    padding: 2px;
    text-align: center;
    font-size: 18px;
    font-family: inherit;
    
}

/* .Toolbar select:focus{
    background-color:#FEEEA2;
} */


.Button{
    padding: 3px;
    font-family: inherit;
    width: 40px;
    height: 28px;
    margin: 0px 5px;
    float: right;
    border-radius: 5px;
    background-color: white;
    cursor:pointer;
    font-weight: 800;
    
}

.Button:hover {
    background-color:#FEEEA2; /* Green */
    color: black;
    font-weight: bold;
  }


