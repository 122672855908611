.Footer{
    background-color:#296eab;
    padding-bottom: 0px;
    text-align: center;
    width:100%;
    height: fit-content;
}
.Footer p {
    padding-bottom: 15px;
    padding-top: 3px;
    font-size: 10px;
}

.Footer a{
    text-decoration:none;
    color: black;  
}

.Footer a:hover{
    text-decoration:underline;
    
}
