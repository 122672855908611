.AboutUs{
    width: 68%;
    height: 650px;
    box-sizing: border-box;
    overflow:scroll;
    background-color: #F8F8FF;
    font-size: 15px;
}

.AboutUs h3{
    text-align: center;
    background-color:#FEEEA2;
    padding: 4px;
    border-radius: 5px;
}

.AboutUs h5{
    padding: 4px;

}

.AboutUs p{
    text-align: justify;
    padding: 5px;
}