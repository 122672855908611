.SchemeDetails{
    width: 68%;
    height: 650px;
    box-sizing: border-box;
    overflow:scroll;
    background-color: #F8F8FF;
   display: flex;
   flex-wrap: wrap;
}

.SchemeDetails p.label{
    font-weight: bold;
    background-color: #FEEEA2;
    padding: 7px;
    border-radius: 3px;
}

.SchemeDetails img{
    width: 100%;
    height: 400px;
}