.Scheme{
    width: 300px;
    margin: 10px;
    height: 300px;
    padding: 0px 0px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.Scheme p{
    width: auto;
    height: 40px;
    padding: 10px;
    font-style: inherit;
    font-weight: bold;
    color: black;
    background-color: #FEEEA2;
    text-align: center;
    align-items: center;
    border-radius: 3px;
    border: black 1px solid;
}

.Scheme img{
    width: 300px;
    height: 210px; 
    border-radius: 3px;
    border: black 1px solid;
}

.Scheme a{
    text-decoration: none;
}