.Schemes{
    width: 68%;
    height: 650px;
    box-sizing: border-box;
    overflow:scroll;
    background-color: #F8F8FF;
   display: flex;
   flex-wrap: wrap;
}

